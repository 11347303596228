var App = App || {};

App.Alert = (function ($) {
    'use strict';

    var $alerts;

    function init() {
        $alerts = $('.site-alert');

        $alerts.each(function () {
            var $alert = $(this),
                alertHash = $alert.data('alert-hash');

            if ($alert) {
                $alert.on('click', '.site-alert__dismiss', function (e) {
                    e.preventDefault();
                    dismissAlert($alert, alertHash);
                });
            }
        });
    }

    function dismissAlert($alert, alertHash) {
        $alert.slideUp(300);
        App.Cookies.setItem('AlertDismissed', alertHash, Infinity, '/', window.location.hostname, window.location.protocol === 'https:');
    }

    function alertDismissed(alertHash) {
        return App.Cookies.getItem('AlertDismissed') === alertHash ? true : false;
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Alert.init();
});
var App = App || {};

App.Carousel = (function ($) { 

    var $controls = $('.carousel-controls');

    function init() {
        $controls.on('click', '.carousel-btn--play', function () {
            var $banner = $(this).parents('.banner-component');

            $banner.find('.slick-initialized').slick('slickPlay');
            $banner.find('.carousel-btn--pause').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.carousel-btn--play').addClass('d-none').attr('tabindex', '-1');
        });

        $controls.on('click', '.carousel-btn--pause', function () {
            var $banner = $(this).parents('.banner-component');

            $banner.find('.slick-initialized').slick('slickPause');
            $banner.find('.carousel-btn--play').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.carousel-btn--pause').addClass('d-none').attr('tabindex', '-1');
        });
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Carousel.init();
});

var App = App || {};

App.Cookies = (function () {
    'use strict';

    function getItem(sKey) {
        if (!sKey) { return null; }
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    }

    function setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        var sExpires = "";
        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                    break;
                case String:
                    sExpires = "; expires=" + vEnd;
                    break;
                case Date:
                    sExpires = "; expires=" + vEnd.toUTCString();
                    break;
            }
        }
        document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
        return true;
    }

    function removeItem(sKey, sPath, sDomain) {
        if (!this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
    }

    function hasItem(sKey) {
        if (!sKey) { return false; }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }

    function keys() {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
        return aKeys;
    }

    return {
        getItem: getItem,
        setItem: setItem,
        removeItem: removeItem,
        hasItem: hasItem,
        keys: keys
    };
})();
var App = App || {};

App.Forms = (function ($) {

    var $form = $('.umbraco-forms');

    function init() {
        var $summary = $form.find('[class^="validation-summary"]');
        var $message = $('#submitmessage');

        if ($summary.length) {
            $summary.on('DOMSubtreeModified', function () {
                setTimeout(function () {
                    $form.find('.input-validation-error').first().focus();
                }, 1);
            });
        }

        if ($message.length) {
            setTimeout(function () {
                var offset = $message.offset.top + 30;
                $message.focus();
                window.scrollTo(offset, 0);
            }, 1);         
        }
    }
    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Forms.init();
});
var App = App || {};

App.MobileApp = (function ($) {

    function init() {
        new SmartBanner({
            daysHidden: 15,
            daysReminder: 90,
            appStoreLanguage: 'us',
            title: 'Beacon Mobile App',
            author: 'Beacon FCU',
            button: 'VIEW',
            store: {
                ios: 'On the App Store',
                android: 'In Google Play'
            },
            price: {
                ios: 'FREE',
                android: 'FREE'
            }
        });

        var $smartbanner = $(".smartbanner"),
            $smartbannerButton = $smartbanner.find(".smartbanner-button"),
            $smartbannerContainer = $(".smartbanner-container");

        $smartbanner.prependTo("header#site-header");
        $smartbannerButton.addClass("btn btn-sm btn-primary");
        $smartbannerContainer.addClass("container");
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.MobileApp.init();
});

var App = App || {};

App.Navigation = (function ($) {
    var $main = $('nav.main');

    function init() {
        $main.find('.has-child').on('focusin', function () {
            var $this = $(this);
            console.log(this);

            $this.addClass('open-child open-child_mobile');
        });

        $main.find('.has-child').on('focusout', function (e) {
            var $this = $(this);

            if (!$.contains(this, e.relatedTarget)) {
                $this.removeClass('open-child open-child_mobile');
            }
        });
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Navigation.init();
});
var App = App || {};

App.SiteTour = (function ($) {
    'use strict';

    var $siteTour,
        whitelist;

    function init() {
        $siteTour = $('#siteTourModal');

        if (!$siteTour.length) {
            return;
        }

        if (!App.Cookies.getItem('siteTourDismissed') || window.location.hash === "#tour") {
            $siteTour.modal('show');
        }

        $siteTour.each(function () {
            var $modal = $(this);

            if ($modal) {
                $modal.on('click', '.close', function (e) {
                    e.preventDefault();
                    dismissSiteTour($modal, 'siteTour');
                });

                // reset video element when modal window closes
                $modal.on('hidden.bs.modal', function () {
                    var origContent = $(this).html();
                    $(this).html(origContent);
                });
            }
        });

        $('a').on('click', function (e) {
            var $this = $(this);
            var url = $this.attr('href');

            if (url === "#tour") {
                e.preventDefault();
                $siteTour.modal('show');
            }
        });
    }

    function dismissSiteTour() {
        App.Cookies.setItem('siteTourDismissed', true, Infinity, '/', window.location.hostname, window.location.protocol === 'https:');
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.SiteTour.init();
});
var App = App || {};

App.Speedbump = (function ($) {
    'use strict';

    var $speedbump,
        whitelist;

    function init() {
        $speedbump = $('#sbModal');

        if (!$speedbump.length) {
            return;
        }

        whitelist = $speedbump.data("exceptions").split("\n");

        $('a').on('click', function (e) {
            var $this = $(this);
            var url = $this.attr('href');
            var i = 0;
            var initialUrl = url;
            var whitelistedLink = false;
            var attrToggle = $this.attr('data-toggle');

            //If the link is inside the speedbump modal
            if ($this.attr('id') === "sbModalSubmit") {
                e.preventDefault();
                //ga("send", "event", "Outgoing Links", url, document.location.pathname + document.location.search);
                window.open(url);
                $('#sbModal').modal('hide');
            }


            // The default behavior for non white-listed external links (excludes tel, mailto and javascript links)
            else if (this.hostname !== location.hostname
                && url
                && url.indexOf('mailto:') === -1
                && url.indexOf('tel:') === -1
                && url.indexOf('javascript:') === -1
                && attrToggle !== "lightbox" ) {

                // process url
                if (typeof url === "string") {
                    url = url.replace("http://", "").replace("http://", "").replace("https://", "").replace("www.", "").replace("www2.", "");
                }

                for (i = 0; i < whitelist.length; ++i) {
                    whitelist[i] = whitelist[i].replace("http://", "").replace("http://", "").replace("https://", "").replace("www.", "").replace("www2.", "");

                    if (url.startsWith(whitelist[i])) {
                        whitelistedLink = true;
                        break;
                    }
                }

                if (!whitelistedLink) {
                    e.preventDefault();
                    $('#sbModal').modal('show');
                    $('#sbModalSubmit').attr('href', initialUrl);
                }
            }

        });

    }
    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Speedbump.init();
});
var App = App || {};

App.Video = (function ($) {
    var $controls = $('.video-controls');

    function init() {
        $controls.on('click', '.video-btn--play', function () {
            var $banner = $(this).parents('.item');

            $banner.find('.video-player').get(0).play();
            $banner.find('.video-btn--pause').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.video-btn--play').addClass('d-none').attr('tabindex', '-1');
        });

        $controls.on('click', '.video-btn--pause', function () {
            var $banner = $(this).parents('.item');

            $banner.find('.video-player').get(0).pause();
            $banner.find('.video-btn--play').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.video-btn--pause').addClass('d-none').attr('tabindex', '-1');
        });
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Video.init();
});